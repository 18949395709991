<template>
  <h2>UNDER CONSTRUCTION</h2>
  <!--<div class="content">
    <div class="container">
      <GalleryItem
    v-for="item in portfolioItems"
    :key="item.id"
    :imgname="item.imgname"
    :pagename="item.pagename"
    :title="item.title"
    ></GalleryItem>
    </div>
  </div>!-->
</template>
<!--<style scoped>
.container {
  height:1200px;
}
@media screen and (max-width: 1700px) {
    .container {
        height: 920px; 
    }
}
@media screen and (max-width: 1480px) {
    .container {
        height: 820px; 
    }
}
@media screen and (max-width: 1300px) {
    .container {
        height: 800px; 
    }
}
@media screen and (max-width: 1100px) {
    .container {
        height: 620px; 
    }
}
@media screen and (max-width: 768px) {
    .container {
        height: 900px; 
    }
}
</style>
<script>
// @ is an alias to /src
import GalleryItem from '../components/GalleryItem.vue';
export default {
  name: "Art",
  components: {
    GalleryItem
  },
  data() {
    return {
      portfolioItems: [
        {id:1,imgname:'glitchart.png',pagename:"art/glitchart",title:"GLITCH ART"},
        {id:2,imgname:'generativeart.png',pagename:"art/generativeart",title:"GENERATIVE ART"},
        {id:3,imgname:'illustration.png',pagename:"art/illustration",title:"ILLUSTRATION"},
        {id:4,imgname:'graphicdesign.png',pagename:"art/graphicdesign",title:"GRAPHIC DESIGN"},
        {id:4,imgname:'traditional.jpg',pagename:"art/traditionalart",title:"TRADITIONAL ART"},
      ]
    }
  }
};
</script>-->

